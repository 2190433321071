import { groupBy } from "lodash";
import { useState } from "react";
import DocumentTitle from "react-document-title";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useSelector } from "react-redux";
import styled from "styled-components";
import circleCheckIcon from "../../assets/images/circle-check.svg";
import warningWhiteIcon from "../../assets/images/warning-white.svg";
import LogbookDefectRowItem from "../../components/defect-row-item";
import { FormlessSelect } from "../../components/form/select";
import {
  selectCompanyDefects,
  selectCompanyDefectsStatistics,
} from "../../store/features/company.slice";

const DefectHighlight = styled.div`
  height: 84px;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  padding: 0 24px;
  display: flex;
  align-items: center;

  &.alert {
    background-color: #d44215;
  }

  &.warning {
    background-color: #ffaa00;
  }
`;

const Icon = styled.img`
  height: 26px;
`;

const Title = styled.h1`
  font-size: 35px;
  line-height: 36px;
`;

const Subtitle = styled.p`
  font-size: 15px;
  line-height: 18px;
  margin-top: 2px;
`;

const Content = styled.div`
  margin-left: 24px;

  ${Title}, ${Subtitle} {
    color: #fff;
  }
`;

export default function CompanyDefects() {
  const defects = useSelector(selectCompanyDefects);
  const statistics = useSelector(selectCompanyDefectsStatistics);
  const [defectTypeFilter, setDefectTypeFilter] = useState("all");
  const [addressFilter, setAddressFilter] = useState("all");

  // statisticw
  const urgentStat = statistics?.find(
    (stat) => stat.isUrgent === true && !stat.isCompleted,
  );
  const nonUrgentStat = statistics?.find(
    (stat) => !stat.isUrgent && !stat.isCompleted,
  );
  const completedStat = statistics?.find((stat) => stat.isCompleted);

  // filtered defects
  let defectsToShow = defects;

  if (defectTypeFilter) {
    if (defectTypeFilter === "completed") {
      defectsToShow = defectsToShow.filter(
        (defect) => defect.status === "Completed",
      );
    }
    if (defectTypeFilter === "progress") {
      defectsToShow = defectsToShow.filter(
        (defect) => defect.status !== "Completed",
      );
    }
    if (defectTypeFilter === "urgent") {
      defectsToShow = defectsToShow.filter((defect) => defect.isUrgent);
    }
  }
  if (addressFilter && addressFilter !== "all") {
    defectsToShow = defectsToShow.filter(
      (defectToShow) => defectToShow?.property?.description === addressFilter,
    );
  }

  return (
    <DocumentTitle title="Issues | inndox">
      <Container className="folder">
        <Row className="margin-bottom-2">
          <Col xs={12}>
            <h2 className="name">Issues</h2>
            <p className="description">Manage issue log</p>
          </Col>
        </Row>

        {/* Badges */}
        <Row>
          {/* Urgent */}
          <Col xs={12} md={4}>
            <DefectHighlight className="alert margin-top-1">
              <Icon src={warningWhiteIcon} alt="warning" />

              <Content>
                <Title>{urgentStat?.count}</Title>
                <Subtitle>urgent issues</Subtitle>
              </Content>

              <Content style={{ marginLeft: "auto" }}>
                <Title>&nbsp;</Title>
                <Subtitle>{urgentStat?.todayCount} today</Subtitle>
              </Content>
            </DefectHighlight>
          </Col>

          {/* All Open Claims */}
          <Col xs={12} md={4}>
            <DefectHighlight className="warning margin-top-1">
              <Icon src={warningWhiteIcon} alt="warning" />

              <Content>
                <Title>{nonUrgentStat?.count}</Title>
                <Subtitle>issues</Subtitle>
              </Content>

              <Content style={{ marginLeft: "auto" }}>
                <Title>&nbsp;</Title>
                <Subtitle>{nonUrgentStat?.todayCount} today</Subtitle>
              </Content>
            </DefectHighlight>
          </Col>

          {/* Completed */}
          <Col xs={12} md={4}>
            <DefectHighlight className="margin-top-1">
              <Icon src={circleCheckIcon} alt="check" />

              <Content>
                <Title style={{ color: "#2D3540" }}>
                  {completedStat?.count}
                </Title>
                <Subtitle style={{ color: "#ADADB0" }}>
                  issues completed
                </Subtitle>
              </Content>

              <Content style={{ marginLeft: "auto" }}>
                <Title style={{ color: "#2D3540" }}>&nbsp;</Title>
                <Subtitle style={{ color: "#ADADB0" }}>
                  {completedStat?.todayCount} today
                </Subtitle>
              </Content>
            </DefectHighlight>
          </Col>
        </Row>

        {/* Filters */}
        <Row className="margin-top-2">
          <Col xs={12} sm={3} md={3}>
            <FormlessSelect
              input={{
                onChange: (option) => setDefectTypeFilter(option.value),
              }}
              defaultValue={{ label: "All issues", value: "all" }}
              items={[
                { label: "All issues", value: "all" },
                { label: "Urgent", value: "urgent" },
                { label: "In progress", value: "progress" },
                { label: "Completed", value: "completed" },
              ]}
            />
          </Col>

          <Col xs={12} sm={6} md={5}>
            <Visible xs>
              <br />
            </Visible>
            <FormlessSelect
              input={{
                onChange: (option) => setAddressFilter(option.value),
              }}
              canSearch
              defaultValue={{ label: "All logbooks", value: "all" }}
              items={[
                { label: "All logbooks", value: "all" },
                ...Object.keys(
                  groupBy(defects, (defect) => defect?.property?.description),
                ).map((address) => ({
                  label: address,
                  value: address,
                })),
              ]}
            />
          </Col>
        </Row>

        {/* Defects List */}
        <Row id="documents-container" className="margin-top-3">
          <Col xs={12}>
            <div className="document-list">
              <Row>
                {/* Status */}
                <Hidden xs>
                  <Col xs={3} sm={2} className="col-head">
                    Status
                  </Col>
                </Hidden>

                {/* Created On */}
                <Hidden xs>
                  <Col sm={2} className="col-head">
                    Reported on
                  </Col>
                </Hidden>

                {/* Address */}
                <Hidden xs>
                  <Col sm={4} className="col-head">
                    Asset
                  </Col>
                </Hidden>

                {/* Subject */}
                <Col xs={9} sm={3} className="col-head">
                  Subject
                </Col>

                <Col xs={3} sm={1} className="col-head">
                  &nbsp;
                </Col>
              </Row>

              {defectsToShow
                .map((defect, index) => (
                  <LogbookDefectRowItem
                    key={index}
                    defect={defect}
                    propertyId={defect.property.id}
                    source="global"
                    isCurrentUserACompany
                    showAddress
                  />
                ))
                .reverse()}
            </div>
          </Col>
        </Row>
      </Container>
    </DocumentTitle>
  );
}
