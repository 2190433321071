import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { Navigate, Route, Routes } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { CompanyRoutes, TemplateRoutes } from ".";
import useCompanyData from "../hooks/company-data";
import NewLogbookDetails from "../pages/common/new-logbook/logbook-details";
import NewLogbookFirstFile from "../pages/common/new-logbook/logbook-first-file";
import NewLogbookType from "../pages/common/new-logbook/logbook-type";
import ViewContact from "../pages/common/view-contact";
import AddOrEditCompanyContact from "../pages/company/add-or-edit-contact";
import AddOrEditTemplate from "../pages/company/add-or-edit-template";
import AssignClaim from "../pages/company/assign-claim";
import CompanyContacts from "../pages/company/contacts";
import CompanyDefects from "../pages/company/defects";
import CompanyHome from "../pages/company/home";
import CompanyLogbooks from "../pages/company/logbooks";
import SubCompanies from "../pages/company/sub-companies";
import CompanyTemplates from "../pages/company/templates";
import ViewCompanyDefect from "../pages/company/view-company-defect";
import CompanyLogbookSiteMap from "./company-logbook-site-map";
import TemplateSiteMap from "./templates-site-map";

export default function CompanySiteMap() {
  const isFetchingData = useCompanyData();

  if (isFetchingData) {
    return (
      <DocumentTitle title="Loading...">
        <Container>
          <Row>
            <Col xs={12} className="flex center">
              <ClipLoader loading size={16} color="#bdd23f" />
            </Col>
          </Row>
        </Container>
      </DocumentTitle>
    );
  }

  return (
    <Routes>
      <Route path={CompanyRoutes.home} element={<CompanyHome />} />
      <Route path={CompanyRoutes.subCompanies} element={<SubCompanies />} />
      {/* Edit template */}
      <Route
        path={CompanyRoutes.editTemplate}
        element={<AddOrEditTemplate />}
      />

      <Route path={CompanyRoutes.newLogbookType} element={<NewLogbookType />} />
      <Route
        path={CompanyRoutes.newLogbookDetails}
        element={<NewLogbookDetails />}
      />
      <Route
        path={CompanyRoutes.newLogbookFirstFile}
        element={<NewLogbookFirstFile />}
      />

      {/* Contacts */}
      <Route path={CompanyRoutes.contacts} element={<CompanyContacts />} />
      <Route
        path={CompanyRoutes.viewCompanyContact}
        element={<ViewContact />}
      />
      <Route
        path={CompanyRoutes.addCompanyContact}
        element={<AddOrEditCompanyContact />}
      />
      <Route
        path={CompanyRoutes.editCompanyContact}
        element={<AddOrEditCompanyContact />}
      />

      {/* Defects */}
      <Route path={CompanyRoutes.defects} element={<CompanyDefects />} />
      <Route
        path={CompanyRoutes.companyDefect}
        element={<ViewCompanyDefect />}
      />
      <Route
        path={CompanyRoutes.assignCompanyDefect}
        element={<AssignClaim />}
      />

      {/* Logbooks */}
      <Route path={CompanyRoutes.logbooks} element={<CompanyLogbooks />} />
      <Route
        path={CompanyRoutes.logbook + "/*"}
        element={<CompanyLogbookSiteMap />}
      />

      {/* Templates */}
      <Route path={TemplateRoutes.templates} element={<CompanyTemplates />} />
      <Route
        path={TemplateRoutes.newTemplate}
        element={<AddOrEditTemplate />}
      />
      <Route
        path={TemplateRoutes.template + "/*"}
        element={<TemplateSiteMap />}
      />

      {/* Redirect from everything else */}
      <Route path="*" element={<Navigate to={CompanyRoutes.home} />} />
    </Routes>
  );
}
