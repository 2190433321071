import { SimpleTreeView, TreeItem2 } from "@mui/x-tree-view";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { Container, Hidden } from "react-grid-system";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DocumentsApi } from "../../api/documents";
import { FoldersApi } from "../../api/folders";
import caretRightIcon from "../../assets/images/caret-right.svg";
import deleteIcon from "../../assets/images/circle-xmark-solid.svg";
import privateFolderIcon from "../../assets/images/folder-private.svg";
import folderIcon from "../../assets/images/folder.svg";
import emptyFolderIcon from "../../assets/images/folder_empty.svg";
import sharedFolderIcon from "../../assets/images/folder_icon_highlighted.svg";
import dashboardIcon from "../../assets/images/folders-home.svg";
import lockIcon from "../../assets/images/lock_icon.svg";
import AddOrEditFolderModal from "../../components/add-or-edit-folder.modal";
import AddOrEditLinkModal from "../../components/add-or-edit-link.modal";
import AddOrEditSubFolderModal from "../../components/add-or-edit-sub-folder.modal";
import FolderInformationModal from "../../components/folder-information.modal";
import { handleError } from "../../components/helpers";
import Modal from "../../components/modal";
import MoreMenu, {
  ADD_LINK_TO_FOLDER_MENU_ITEM,
  ADD_SUB_FOLDER_MENU_ITEM,
  DELETE_FOLDER_MENU_ITEM,
  DIVIDER_MENU_ITEM,
  EDIT_FOLDER_MENU_ITEM,
  SHARE_FOLDER_MENU_ITEM,
  UPLOAD_FILES_TO_FOLDER_MENU_ITEM,
  VIEW_FOLDER_INFO_MENU_ITEM,
} from "../../components/more-menu";
import ShareLogbookOrContent from "../../components/share-logbook-or-content";
import Toast from "../../components/toast";
import FolderAndFileUploadModal from "../../components/upload-files-and-folders.modal";
import { getDocuments } from "../../hooks/document-selector";
import { CompanyRoutes, OwnerRoutes, TemplateRoutes } from "../../routes";
import {
  removeLogbookFolder,
  removeLogbookSubFolder,
  updateLogbookDocument,
  updateLogbookFolder,
} from "../../store/features/logbooks.slice";
import {
  removeTemplateFolder,
  removeTemplateSubFolder,
  updateTemplateFolder,
} from "../../store/features/templates.slice";
import DocumentList from "./document-list";

const ContentContainer = styled.div`
  display: flex;
  align-items: stretch;
`;
const Left = styled.div`
  flex-basis: 25%;
  min-width: 200px;
  border-right: 1px solid #dde0e3;
  padding: 24px 16px;
`;

const TreeFolderItemContainer = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2px;
  border-radius: 4px;
`;

const FolderIconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const FolderLabel = styled.p`
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Right = styled.div`
  flex-basis: 75%;
  padding: 24px 20px;

  @media only screen and (max-width: 576px) {
    flex-basis: 100%;
  }
`;

const RenderedFolderHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RenderedFolderTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
  }
`;

const FolderTitle = styled.h2`
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
`;

const PreviousFolderTitle = styled(FolderTitle)`
  color: #2d354080;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    font-size: 20px;
  }
`;
const CurrentFolderTitle = styled(FolderTitle)``;

const FolderIcon = styled.img`
  height: 18px;
`;

const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 144px;
`;

const PlaceholderTitle = styled.h1`
  font-size: 32px;
  color: #adadb0;
`;

const LockIcon = styled.img`
  position: absolute;
  left: auto;
  right: 2px;
  top: auto;
  bottom: 3px;
  width: 10px;
`;

function TreeFolderItem({
  id,
  label,
  isPrivate,
  isShared,
  hasDocuments,
  isLocked,
  onFileDrop,
  dragData,
  onClick,
}) {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  let icon =
    id === "folders"
      ? dashboardIcon
      : hasDocuments
        ? folderIcon
        : emptyFolderIcon;
  if (isPrivate) {
    icon = privateFolderIcon;
  } else if (isShared) {
    icon = sharedFolderIcon;
  }

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDragStart = (event) => {
    event.dataTransfer.setData("text/plain", JSON.stringify(dragData));
  };

  return (
    <TreeFolderItemContainer
      style={isDraggingOver ? { boxShadow: "0 0 4px 1px #d8d8d8" } : undefined}
      onDrop={onFileDrop}
      onDragOver={onDragOver}
      onDragEnter={() => setIsDraggingOver(true)}
      onDragLeave={() => setIsDraggingOver(false)}
      draggable
      onDragStart={onDragStart}
      onClick={onClick}
    >
      <FolderIconContainer>
        <FolderIcon src={icon} />

        {isLocked ? <LockIcon src={lockIcon} /> : null}
      </FolderIconContainer>

      <FolderLabel>{label}</FolderLabel>
    </TreeFolderItemContainer>
  );
}

const SubFolderItemContainer = styled.div`
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  background-color: #f6f7fb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-right: 16px;
  cursor: pointer;
`;

const SubFolderIconContainer = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubFolderIcon = styled.img`
  height: 32px;
`;

const SubFolderLabel = styled.p`
  width: 100px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
`;

function SubFolderItem({
  label,
  isPrivate,
  isShared,
  link,
  logbookId,
  folderId,
  subFolderId,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  let icon = folderIcon;
  if (isPrivate) {
    icon = privateFolderIcon;
  }
  if (isShared) {
    icon = sharedFolderIcon;
  }

  // Subfolder item can only accept documents
  const onExistingFileDropped = async (event) => {
    event.preventDefault();

    const dataFromTransfer = event.dataTransfer.getData("text/plain");

    if (dataFromTransfer && !isNaN(parseInt(dataFromTransfer))) {
      // string/number
      const documentId = parseInt(dataFromTransfer);

      // move this document to the sub folder
      try {
        const updatedFolder = await DocumentsApi.moveDocumentToSubFolder(
          logbookId,
          documentId,
          folderId,
          subFolderId,
        );

        dispatch(updateLogbookFolder(updatedFolder));
        dispatch(
          updateLogbookDocument({
            id: documentId,
            folderId,
            subfolderId: subFolderId,
          }),
        );
      } catch (e) {
        handleError(e);
      }
    } else {
      toast.error(
        <Toast
          title="Action not allowed!"
          details="You can not add folders to a subfolder."
        />,
      );
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDragStart = (event) => {
    event.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        folderId,
        subFolderId,
      }),
    );
  };

  return (
    <SubFolderItemContainer
      style={isDraggingOver ? { boxShadow: "0 0 4px 1px #d8d8d8" } : undefined}
      onClick={() => navigate("/" + link)}
      onDrop={onExistingFileDropped}
      onDragOver={onDragOver}
      onDragEnter={() => setIsDraggingOver(true)}
      onDragLeave={() => setIsDraggingOver(false)}
      draggable
      onDragStart={onDragStart}
    >
      <SubFolderIconContainer>
        <SubFolderIcon src={icon} />
      </SubFolderIconContainer>

      <SubFolderLabel>{label}</SubFolderLabel>
    </SubFolderItemContainer>
  );
}

const getFolderPath = ({
  isCompanyUser,
  isPartOfTemplate,
  logbookId,
  folderId,
  subFolderId,
}) => {
  let url;
  if (subFolderId) {
    // navigate to subfolder
    url = isCompanyUser
      ? isPartOfTemplate
        ? CompanyRoutes.basePath.replace("/*", "") +
          "/" +
          TemplateRoutes.template.replace(":templateId", logbookId) +
          "/" +
          TemplateRoutes.templateSubFolder
            .replace(":folderId", folderId)
            .replace(":subFolderId", subFolderId)
        : CompanyRoutes.basePath.replace("/*", "") +
          "/" +
          CompanyRoutes.logbook.replace(":logbookId", logbookId) +
          "/" +
          CompanyRoutes.logbookSubFolder
            .replace(":folderId", folderId)
            .replace(":subFolderId", subFolderId)
      : OwnerRoutes.basePath.replace("/*", "") +
        "/" +
        OwnerRoutes.logbook.replace(":logbookId", logbookId) +
        "/" +
        OwnerRoutes.logbookSubFolder
          .replace(":folderId", folderId)
          .replace(":subFolderId", subFolderId);
  } else {
    // navigate to folder
    url = isCompanyUser
      ? isPartOfTemplate
        ? CompanyRoutes.basePath.replace("/*", "") +
          "/" +
          TemplateRoutes.template.replace(":templateId", logbookId) +
          "/" +
          TemplateRoutes.templateFolder.replace(":folderId", folderId)
        : CompanyRoutes.basePath.replace("/*", "") +
          "/" +
          CompanyRoutes.logbook.replace(":logbookId", logbookId) +
          "/" +
          CompanyRoutes.logbookFolder.replace(":folderId", folderId)
      : OwnerRoutes.basePath.replace("/*", "") +
        "/" +
        OwnerRoutes.logbook.replace(":logbookId", logbookId) +
        "/" +
        OwnerRoutes.logbookFolder.replace(":folderId", folderId);
  }

  return url;
};

function CustomTreeItem({
  item,
  itemId,
  hasDocuments,
  onClick,
  children,
  logbookId,
  folderId,
  subFolderId,
  isPartOfTemplate,
}) {
  const dispatch = useDispatch();

  const onExistingFileDropped = async (event) => {
    event.preventDefault();

    const dataFromTransfer = event.dataTransfer.getData("text/plain");

    if (dataFromTransfer) {
      if (isNaN(parseInt(dataFromTransfer))) {
        // object
        try {
          const data = JSON.parse(dataFromTransfer);
          const dataFolderId = data.folderId;
          const dataSubFolderId = data.subFolderId;

          // move this folder to the target folder
          try {
            if (dataSubFolderId) {
              // TODO: need to update local state
              const updatedTargetFolder = await FoldersApi.moveSubFolder(
                logbookId,
                dataFolderId,
                dataSubFolderId,
                folderId, // This is the target folder for the folder move
              );
              // For updating local state
              // When we move a folder inside another folder,
              // returned folder is target folder
              // which contains the newly created subfolder
              //
              // To update local state, we need to remove
              // the folder from the logbook and update the target
              // folder
              if (isPartOfTemplate) {
                dispatch(
                  removeTemplateSubFolder({
                    id: dataSubFolderId,
                    folderId: dataFolderId,
                  }),
                );
                dispatch(updateTemplateFolder(updatedTargetFolder));
              } else {
                dispatch(
                  removeLogbookSubFolder({
                    id: dataSubFolderId,
                    folderId: dataFolderId,
                  }),
                );
                dispatch(updateLogbookFolder(updatedTargetFolder));
              }
            } else {
              const updatedTargetFolder = await FoldersApi.moveFolder(
                logbookId,
                dataFolderId,
                folderId, // This is the target folder for the folder move
              );

              // For updating local state
              // When we move a folder inside another folder,
              // returned folder is target folder
              // which contains the newly created subfolder
              //
              // To update local state, we need to remove
              // the folder from the logbook and update the target
              // folder
              if (isPartOfTemplate) {
                dispatch(removeTemplateFolder(dataFolderId));
                dispatch(updateTemplateFolder(updatedTargetFolder));
              } else {
                dispatch(removeLogbookFolder(dataFolderId));
                dispatch(updateLogbookFolder(updatedTargetFolder));
              }
            }
          } catch (e) {
            handleError(e);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        const documentId = parseInt(dataFromTransfer);

        // move this document to folder
        try {
          if (subFolderId) {
            const updatedFolder = await DocumentsApi.moveDocumentToSubFolder(
              logbookId,
              documentId,
              folderId,
              subFolderId,
            );
            dispatch(updateLogbookFolder(updatedFolder));
            dispatch(
              updateLogbookDocument({
                id: documentId,
                folderId,
                subfolderId: subFolderId,
              }),
            );
          } else {
            const updatedFolder = await DocumentsApi.moveDocumentToFolder(
              logbookId,
              documentId,
              folderId,
              subFolderId,
            );
            dispatch(updateLogbookFolder(updatedFolder));
            dispatch(
              updateLogbookDocument({
                id: documentId,
                folderId,
                subfolderId: subFolderId,
              }),
            );
          }
        } catch (e) {
          handleError(e);
        }
      }
    }
  };

  return (
    <TreeItem2
      key={`item-${item.id}`}
      itemId={itemId}
      label={
        <TreeFolderItem
          id={item.id}
          isPrivate={item.isPrivate}
          isShared={item.isShared}
          label={item.name}
          hasDocuments={hasDocuments}
          isLocked={item.isLocked}
          onFileDrop={onExistingFileDropped}
          dragData={{ folderId, subFolderId }}
          onClick={onClick}
        />
      }
    >
      {children}
    </TreeItem2>
  );
}

/**
 * @typedef {Object} LogbookFoldersProps
 * @property {boolean} [isPartOfTemplate]
 * @property {boolean} [isPartOfSharedLogbook]
 * @property {boolean} [isCompanyUser]
 * @property {inndox.Folder[]} folders
 * @property {inndox.LogbookDocument[]} documents
 * @property {number} logbookId
 *
 */

/**
 *
 * @param {LogbookFoldersProps} props
 */
export default function LogbookFolders({
  isPartOfTemplate,
  isPartOfSharedLogbook,
  isCompanyUser,
  folders,
  documents: allDocuments,
  logbookId,
}) {
  const navigate = useNavigate();
  const params = useParams();

  const folderIdString = params["folderId"];
  const subFolderIdString = params["subFolderId"];
  const folderId = folderIdString ? parseInt(folderIdString) : undefined;
  const folder = folderId ? folders.find((f) => f.id === folderId) : undefined;
  const subFolderId = subFolderIdString
    ? parseInt(subFolderIdString)
    : undefined;
  const subFolder =
    subFolderId && folder
      ? folder.subfolders.find((f) => f.id === subFolderId)
      : undefined;
  const renderedFolder = subFolder || folder || undefined;
  const [selectedItemId, setSelectedItemId] = useState();
  const [wantsToDeleteFolder, setWantsToDeleteFolder] = useState(false);
  const [wantsToShareFolder, setWantsToShareFolder] = useState(false);
  const [wantsAddOrEditSubFolder, setWantsAddOrEditSubFolder] = useState(false);
  const [wantsToEditFolder, setWantsToEditFolder] = useState(false);
  const [wantsToUploadFiles, setWantsToUploadFiles] = useState(false);
  const [wantsToUploadLink, setWantsToUploadLink] = useState(false);
  const [wantsToViewFolderInformation, setWantsToViewFolderInformation] =
    useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const itemId = subFolder
      ? `subfolder-${subFolder.id}`
      : folder
        ? `folder-${folder.id}`
        : undefined;
    console.log(itemId);
    setSelectedItemId(itemId);
  }, [subFolder, folder]);

  if ((folderId && !folder) || (subFolderId && !subFolder)) {
    navigate(-1);
  }

  const documents = getDocuments(allDocuments, folderId, subFolderId);

  const data = folders.map((folder) => {
    const folderData = {
      id: folder.id,
      name: folder.name,
      isPrivate: folder.isPrivate,
      isShared: folder.sharePermissions?.length > 0,
      isLocked: !folder.canDelete,
      children: undefined,
    };

    if (folder.subfolders?.length) {
      folderData.children = folder.subfolders.map((subFolder) => ({
        id: subFolder.id,
        name: subFolder.name,
      }));
    }
    return folderData;
  });

  /**
   *
   * @param {string} value
   */
  const actionSelectedMenuOption = (value) => {
    switch (value) {
      case UPLOAD_FILES_TO_FOLDER_MENU_ITEM.value: {
        setWantsToUploadFiles(true);
        break;
      }
      case ADD_LINK_TO_FOLDER_MENU_ITEM.value: {
        setWantsToUploadLink(true);
        break;
      }

      case ADD_SUB_FOLDER_MENU_ITEM.value: {
        setWantsAddOrEditSubFolder(true);
        break;
      }
      case EDIT_FOLDER_MENU_ITEM.value: {
        // edit folder
        if (subFolder) {
          setWantsAddOrEditSubFolder(true);
        } else {
          setWantsToEditFolder(true);
        }
        break;
      }
      case VIEW_FOLDER_INFO_MENU_ITEM.value: {
        setWantsToViewFolderInformation(true);
        break;
      }
      case SHARE_FOLDER_MENU_ITEM.value: {
        // share folder
        setWantsToShareFolder(true);
        break;
      }

      case DELETE_FOLDER_MENU_ITEM.value: {
        // delete folder
        setWantsToDeleteFolder(true);
        break;
      }

      default:
        break;
    }
  };

  const navigateToFolder = (selectedFolderId, selectedSubFolderId) => {
    let url;

    if (selectedFolderId === "folders") {
      // dashboard selected
      url = isCompanyUser
        ? isPartOfTemplate
          ? CompanyRoutes.basePath.replace("/*", "") +
            "/" +
            TemplateRoutes.template.replace(":templateId", logbookId)
          : CompanyRoutes.basePath.replace("/*", "") +
            "/" +
            CompanyRoutes.logbook.replace(":logbookId", logbookId)
        : OwnerRoutes.basePath.replace("/*", "") +
          "/" +
          OwnerRoutes.logbook.replace(":logbookId", logbookId);
    } else {
      url = getFolderPath({
        folderId: selectedFolderId,
        subFolderId: selectedSubFolderId,
        logbookId,
        isCompanyUser,
        isPartOfTemplate,
      });
    }

    navigate("/" + url);
  };

  const goToParentFolder = () => {
    const url = getFolderPath({
      folderId: folder.id,
      logbookId,
      isCompanyUser,
      isPartOfTemplate,
    });
    navigate("/" + url);
  };

  const isCurrentFolderAtTopLevel = renderedFolder?.id === folderId;
  const moreMenuOptions = [
    UPLOAD_FILES_TO_FOLDER_MENU_ITEM,
    ADD_LINK_TO_FOLDER_MENU_ITEM,
    DIVIDER_MENU_ITEM,
  ];
  if (isCurrentFolderAtTopLevel) {
    moreMenuOptions.push(ADD_SUB_FOLDER_MENU_ITEM);
  }
  moreMenuOptions.push(...[EDIT_FOLDER_MENU_ITEM, VIEW_FOLDER_INFO_MENU_ITEM]);
  if (isCurrentFolderAtTopLevel) {
    moreMenuOptions.push(...[DIVIDER_MENU_ITEM, SHARE_FOLDER_MENU_ITEM]);
  }
  moreMenuOptions.push(...[DIVIDER_MENU_ITEM, DELETE_FOLDER_MENU_ITEM]);

  return (
    <DocumentTitle title={`${renderedFolder.name} | inndox`}>
      <Container className="folder">
        <div className="document-list" style={{ padding: 0 }}>
          <ContentContainer>
            <Hidden xs>
              <Left>
                <SimpleTreeView
                  experimentalFeatures={{
                    indentationAtItemLevel: true,
                    itemsReordering: true,
                  }}
                  itemChildrenIndentation={32}
                  itemsReordering={true}
                  selectedItems={selectedItemId}
                  expansionTrigger="iconContainer"
                >
                  <CustomTreeItem
                    itemId={"folders"}
                    item={{
                      id: "folders",
                      name: "Dashboard",
                    }}
                    logbookId={logbookId}
                    isPartOfTemplate={isPartOfTemplate}
                    onClick={() => navigateToFolder("folders")}
                  >
                    {data.map((item) => {
                      if (item.children) {
                        return (
                          <CustomTreeItem
                            key={`item-${item.id}`}
                            itemId={"folder-" + item.id}
                            hasDocuments={
                              getDocuments(allDocuments, item.id)?.length
                            }
                            item={item}
                            logbookId={logbookId}
                            folderId={item.id}
                            isPartOfTemplate={isPartOfTemplate}
                            onClick={() => navigateToFolder(item.id)}
                          >
                            {item.children.map((subItem) => (
                              <CustomTreeItem
                                key={`subitem-${item.id}`}
                                itemId={"subfolder-" + subItem.id}
                                hasDocuments={
                                  getDocuments(
                                    allDocuments,
                                    item.id,
                                    subItem.id,
                                  )?.length
                                }
                                item={subItem}
                                logbookId={logbookId}
                                folderId={item.id}
                                subFolderId={subItem.id}
                                isPartOfTemplate={isPartOfTemplate}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigateToFolder(item.id, subItem.id);
                                }}
                              />
                            ))}
                          </CustomTreeItem>
                        );
                      }

                      return (
                        <CustomTreeItem
                          key={`item-${item.id}`}
                          itemId={"folder-" + item.id}
                          hasDocuments={
                            getDocuments(allDocuments, item.id)?.length
                          }
                          item={item}
                          logbookId={logbookId}
                          folderId={item.id}
                          isPartOfTemplate={isPartOfTemplate}
                          onClick={() => navigateToFolder(item.id)}
                        />
                      );
                    })}
                  </CustomTreeItem>
                </SimpleTreeView>
              </Left>
            </Hidden>

            <Right>
              {renderedFolder ? (
                <>
                  <RenderedFolderHeader>
                    <RenderedFolderTitleContainer>
                      {subFolder ? (
                        <>
                          <PreviousFolderTitle onClick={goToParentFolder}>
                            {folder.name}
                          </PreviousFolderTitle>

                          <Hidden xs>
                            <img src={caretRightIcon} alt="caret" />
                          </Hidden>

                          <CurrentFolderTitle>
                            {subFolder.name}
                          </CurrentFolderTitle>
                        </>
                      ) : (
                        <CurrentFolderTitle>
                          {renderedFolder.name}
                        </CurrentFolderTitle>
                      )}
                    </RenderedFolderTitleContainer>

                    <MoreMenu
                      options={moreMenuOptions}
                      onSelect={actionSelectedMenuOption}
                    />
                  </RenderedFolderHeader>

                  {isCurrentFolderAtTopLevel && (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {folder.subfolders.map((sf) => (
                        <SubFolderItem
                          key={sf.id}
                          label={sf.name}
                          isPrivate={folder.isPrivate}
                          isShared={folder.sharePermissions?.length > 0}
                          link={getFolderPath({
                            folderId,
                            subFolderId: sf.id,
                            logbookId,
                            isCompanyUser,
                            isPartOfTemplate,
                          })}
                          logbookId={logbookId}
                          folderId={folder.id}
                          subFolderId={sf.id}
                        />
                      ))}
                    </div>
                  )}

                  <DocumentList
                    documents={documents}
                    documentsAreInsideAFolder
                    isInsideATemplate={isPartOfTemplate}
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 16,
                    }}
                  />
                </>
              ) : (
                <PlaceholderContainer>
                  <PlaceholderTitle>
                    Select a folder to view it here
                  </PlaceholderTitle>
                </PlaceholderContainer>
              )}
            </Right>
          </ContentContainer>
        </div>

        {/* Share folder */}
        <ShareLogbookOrContent
          isOpen={wantsToShareFolder}
          onClose={() => setWantsToShareFolder(false)}
          folder={folder}
        />

        {/* Delete folder */}
        <Modal
          icon={deleteIcon}
          isOpen={wantsToDeleteFolder}
          title="Are you sure you want to delete this folder?"
          onClose={() => setWantsToDeleteFolder(false)}
        >
          {(() => {
            const itemsCount =
              documents.length +
              (isCurrentFolderAtTopLevel ? folder.subfolders.length : 0);

            return (
              <p className="margin-top-2">
                This folder contains {itemsCount}
                {itemsCount > 1 ? ` items` : ` item`}. Deleting it will
                permanently remove all contents, including files, subfolders,
                and links. This action cannot be undone.
              </p>
            );
          })()}

          <div className="flex margin-top-4">
            <Form
              onSubmit={async () => {
                try {
                  if (isCurrentFolderAtTopLevel) {
                    await FoldersApi.removeFolder(logbookId, folder.id);

                    if (isPartOfTemplate) {
                      dispatch(removeTemplateFolder(folderId));
                    } else {
                      dispatch(removeLogbookFolder(folderId));
                    }
                  } else {
                    await FoldersApi.removeSubFolder(
                      logbookId,
                      folderId,
                      subFolderId,
                    );

                    if (isPartOfTemplate) {
                      dispatch(
                        removeTemplateSubFolder({
                          folderId,
                          id: subFolderId,
                        }),
                      );
                    } else {
                      dispatch(
                        removeLogbookSubFolder({
                          folderId,
                          id: subFolderId,
                        }),
                      );
                    }
                  }

                  setWantsToDeleteFolder(false);

                  navigate(
                    isCurrentFolderAtTopLevel
                      ? "/folders"
                      : getFolderPath({
                          isCompanyUser,
                          isPartOfTemplate,
                          logbookId: logbookId,
                          folderId,
                        }),
                  );
                } catch (e) {
                  handleError(e);
                }
              }}
              render={(props) => (
                <button
                  className="button button-danger button-large button-big"
                  style={{ width: 160 }}
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#FF3E3E" />
                  ) : (
                    "Yes, delete"
                  )}
                </button>
              )}
            />
            <button
              className="button button-secondary-link button-large button-big margin-left-1"
              style={{ minWidth: 123 }}
              onClick={() => setWantsToDeleteFolder(false)}
            >
              Cancel
            </button>
          </div>
        </Modal>

        {/* Add/Edit Subfolder */}
        <AddOrEditSubFolderModal
          isVisible={wantsAddOrEditSubFolder}
          onClose={() => setWantsAddOrEditSubFolder(false)}
          subFolder={subFolder} // subfolder present => user inside a subfolder, otherwise adding a new subfolder
          folderId={folderId}
          logbookId={logbookId}
          isPartOfTemplate={isPartOfTemplate}
        />

        {/* Upload doc & folder */}
        {folder && (
          <FolderAndFileUploadModal
            isVisible={wantsToUploadFiles}
            onClose={() => setWantsToUploadFiles(false)}
            logbookId={logbookId}
            folderId={folderId}
            subFolderId={subFolderId}
            isAddingToATemplate={isPartOfTemplate}
          />
        )}

        {/* Add Link */}
        {folder && (
          <AddOrEditLinkModal
            isVisible={wantsToUploadLink}
            onClose={() => setWantsToUploadLink(false)}
            logbookId={logbookId}
            folderId={folderId}
            subFolderId={subFolderId}
            isPartOfTemplate={isPartOfTemplate}
            isPartOfSharedFolder={isPartOfSharedLogbook}
          />
        )}

        {/* Add folder */}
        {folder && (
          <AddOrEditFolderModal
            isVisible={wantsToEditFolder}
            onClose={() => setWantsToEditFolder(false)}
            logbookId={logbookId}
            folder={folder}
            isPartOfTemplate={isPartOfTemplate}
          />
        )}

        {folder && (
          <FolderInformationModal
            isVisible={wantsToViewFolderInformation}
            onClose={() => setWantsToViewFolderInformation(false)}
            folder={folder}
            subFolder={subFolder}
            onEdit={() => {
              setWantsToViewFolderInformation(false);

              if (subFolder) {
                setWantsAddOrEditSubFolder(true);
              } else {
                setWantsToEditFolder(true);
              }
            }}
          />
        )}
      </Container>
    </DocumentTitle>
  );
}
