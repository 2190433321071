import DocumentTitle from "react-document-title";
import { Container } from "react-grid-system";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import privateFolderIcon from "../../assets/images/folder-private.svg";
import folderIcon from "../../assets/images/folder.svg";
import emptyFolderIcon from "../../assets/images/folder_empty.svg";
import sharedFolderIcon from "../../assets/images/folder_icon_highlighted.svg";
import lockIcon from "../../assets/images/lock_icon.svg";

/**
 * @typedef {Object} LogbookFoldersProps
 * @property {inndox.Folder[]} folders
 *
 */

/**
 *
 * @param {LogbookFoldersProps} props
 */
export default function LogbookFolders({ folders }) {
  const location = useLocation();
  const navigate = useNavigate();

  const openFolder = (folderId) => {
    navigate(location.pathname.replace("folders", "folder") + "/" + folderId);
  };

  return (
    <DocumentTitle title={`Logbook Folders | inndox`}>
      <Container className="folder">
        <Content className="document-list">
          <PageTitle>Folders</PageTitle>
          <Folders>
            {folders.map((folder) => {
              let icon =
                folder.documentStatistics.count > 0
                  ? folderIcon
                  : emptyFolderIcon;
              if (folder.isPrivate) {
                icon = privateFolderIcon;
              } else if (folder.sharePermissions.length > 0) {
                icon = sharedFolderIcon;
              }

              return (
                <FolderItem
                  key={folder.id}
                  onClick={() => openFolder(folder.id)}
                >
                  <IconContainer>
                    <FolderIcon src={icon} />

                    {!folder.canDelete && <LockIcon src={lockIcon} />}
                  </IconContainer>
                  <FolderItemTitle>{folder.name}</FolderItemTitle>
                </FolderItem>
              );
            })}
          </Folders>
        </Content>
      </Container>
    </DocumentTitle>
  );
}

const Content = styled.div`
  padding: 40px;

  @media only screen and (max-width: 576px) {
    padding: 16px;
  }
`;

const Folders = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  background-color: #fff;

  @media only screen and (max-width: 576px) {
    gap: 16px;
  }
`;

const FolderItem = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  &:hover {
    background-color: #aeb2c233;
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const FolderItemTitle = styled.p`
  font-size: 16px;
  text-align: center;
  line-height: 24px;
`;

const PageTitle = styled.p`
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 16px;
`;

const FolderIcon = styled.img`
  height: 44px;
`;

const LockIcon = styled.img`
  width: 20px;
  position: absolute;
  right: 0px;
  bottom: 2px;
`;
