import { useSelector } from "react-redux";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";
import LogbookFolder from "../common/logbook-folder";

export default function OwnerLogbookFolder() {
  const logbook = useSelector(selectCurrentLogbook);

  return (
    <LogbookFolder
      logbookId={logbook.id}
      folders={logbook.folders}
      documents={logbook.documents}
    />
  );
}
