import moment from "moment";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import ReactTimeAgo from "react-time-ago";
import styled from "styled-components";
import { DefectsApi } from "../../api/defects";
import { PropertyContactsApi } from "../../api/property-contacts";
import defectSideImage from "../../assets/images/account-side.jpg";
import send from "../../assets/images/send.svg";
import BackButton from "../../components/back-button";
import { FiftyFifty } from "../../components/fifty-fifty";
import TextField from "../../components/form/text-field";
import { getFileTypeIcon, handleError } from "../../components/helpers";
import { updateCompanyDefect } from "../../store/features/company.slice";
import { updateCurrentLogbookDefect } from "../../store/features/logbooks.slice";

const Content = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DefectLabel = styled.span`
  padding: 2px 4px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #bdd23f;
  color: #fff;
  margin-right: 8px;

  &.urgent {
    background-color: #ff3e3e;
    color: #fff;
  }

  &.completed {
    background-color: #d8d8d8;
    color: #fff;
  }
`;

const DefectImageAttachment = styled.img`
  max-width: 180px;
  height: 120px;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 8px;
  border: 1px solid #bfd62f;
`;

const DefectFileAttachment = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  margin-top: 8px;
  margin-right: 8px;
  border: 1px solid #bfd62f;
`;

const DefectFileAttachmentName = styled.div`
  height: 24px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d8d8d8;
  padding: 0 8px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CommentsContainer = styled.div`
  margin-top: 8px;
  padding-bottom: 32px;
`;

const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CommentContainer = styled.div`
  margin-bottom: 16px;
`;

const CommentAuthor = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #2d3540;
`;

const CommentTime = styled.p`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  time {
    color: #adadb0;
  }
`;

const NonAuthorCommentText = styled.p`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #adadb0;
  max-width: 316px;
`;

const CommentText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #2d3540;
  max-width: 316px;
`;

const CommentBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 0;
`;

const PostButton = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #bfd62f;
  flex-shrink: 0;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function CommentBox({ defectId, logbookId, isViewingDefectInsideALogbook }) {
  const dispatch = useDispatch();

  return (
    <Form
      initialValues={{
        logbookId,
        defectId,
      }}
      mutators={{
        setFieldValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      onSubmit={async (values, form) => {
        try {
          // post comment
          const updatedDefect = await DefectsApi.postCommentForDefect(
            values.logbookId,
            values.defectId,
            values.text,
          );

          // update defect depending on where it is
          if (isViewingDefectInsideALogbook) {
            dispatch(updateCurrentLogbookDefect(updatedDefect));
          } else {
            dispatch(updateCompanyDefect(updatedDefect));
          }

          // reset text field value to empty
          form.mutators.setFieldValue("text", "");

          // scroll to bottom
          window.setTimeout(() => {
            const contentContainer = document.getElementById(
              "fifty-fifty-content",
            );
            contentContainer.scrollTop = contentContainer.scrollHeight;
          }, 1000);
        } catch (e) {
          handleError(e);
        }
      }}
      render={(props) => (
        <CommentBoxContainer>
          <TextField
            name="text"
            disabled={props.submitting}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.handleSubmit();
              }
            }}
          />

          <PostButton onClick={props.handleSubmit}>
            {props.submitting ? (
              <ClipLoader loading size={16} color="#fff" />
            ) : (
              <img src={send} alt="Post Comment" />
            )}
          </PostButton>
        </CommentBoxContainer>
      )}
    />
  );
}

/**
 * @typedef {Object} CommentProps
 * @property {inndox.LogbookDefectComment} comment
 */

/**
 *
 * @param {CommentProps} props
 * @returns
 */
function Comment({ comment }) {
  if (comment.authorId) {
    return (
      <CommentContainer>
        <CommentHeader>
          <CommentAuthor>{comment.author}</CommentAuthor>
          <CommentTime>
            <ReactTimeAgo date={new Date(comment.createdOn)} />
          </CommentTime>
        </CommentHeader>

        <CommentText>{comment.text}</CommentText>
      </CommentContainer>
    );
  }

  return (
    <CommentContainer>
      <CommentHeader>
        <NonAuthorCommentText>{comment.text}</NonAuthorCommentText>
        <CommentTime>
          <ReactTimeAgo date={new Date(comment.createdOn)} />
        </CommentTime>
      </CommentHeader>
    </CommentContainer>
  );
}

/**
 *
 * @typedef {Object} ViewClaimProps
 * @property {inndox.LogbookDefect} defect
 */

/**
 *
 * @param {ViewClaimProps} props
 * @returns
 */
export default function ViewClaim({ defect }) {
  const navigate = useNavigate();
  const { pathname, key } = useLocation();
  const { defectId } = useParams();
  const isViewingDefectInsideALogbook = pathname.includes("logbooks");
  const backPath =
    key !== "default"
      ? -1
      : isViewingDefectInsideALogbook
        ? pathname.replace("/defects/" + defectId, "")
        : pathname.replace("/" + defectId, "");
  const [contacts, setContacts] = useState([]);

  const downloadDefectFiles = () => {
    DefectsApi.downloadDefectFiles(defect.property.id, defectId);
  };

  useEffect(() => {
    async function fetchContacts() {
      const contacts = await PropertyContactsApi.fetchLogbookContacts(
        defect.property.id,
      );
      setContacts(contacts);
    }
    fetchContacts();
  }, [defect.property.id]);

  if (!defect) {
    return (
      <DocumentTitle title="Issue Details | inndox">
        <FiftyFifty backgroundImage={defectSideImage}>
          <h1 className="margin-top-1">Oops!</h1>
          <p className="margin-top-1">
            Sorry something went wrong. You can go back and try again.
          </p>
          <button
            className="button button-secondary button-large button-big margin-top-4"
            onClick={() => navigate(backPath)}
          >
            Go back
          </button>
        </FiftyFifty>
      </DocumentTitle>
    );
  }

  const contact = defect.assignedToId
    ? contacts.find((contact) => contact.id === defect.assignedToId)
    : undefined;

  return (
    <DocumentTitle title="Issue details | inndox">
      <FiftyFifty backgroundImage={defectSideImage} stretch expanded>
        <Content className="margin-top-1">
          <BackButton backPath={backPath} />
          <h1 className="margin-bottom-4">Issue details</h1>

          {/* Name & Description */}
          <label>Subject</label>
          <p className="margin-bottom-2">
            <span className="margin-right-1">{defect.subject}</span>

            {defect.isCompleted ? (
              <DefectLabel className="completed">Completed</DefectLabel>
            ) : defect.isUrgent ? (
              <DefectLabel className="urgent">Urgent</DefectLabel>
            ) : (
              <DefectLabel>Incoming</DefectLabel>
            )}
          </p>

          {defect.description ? (
            <>
              <label>Description</label>
              <p className="margin-bottom-2">{defect.description}</p>
            </>
          ) : (
            <noscript />
          )}

          {/* Date Added */}
          <label>Date added</label>
          <p className="margin-bottom-2">
            {moment(defect.createdOn).format("MMMM D, YYYY")}
            {` at `}
            {moment(defect.createdOn).format("h:mm A")}
          </p>

          {/* Assigned To */}
          <label>Assigned To</label>
          <p className="margin-bottom-2">
            {contact
              ? contact?.company || contact?.firstName + " " + contact?.lastName
              : "-"}
          </p>

          {/* Attachements */}
          <label>Attachments</label>
          {defect.files.length ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {defect.files.map((file) => {
                  const isNotAnImage =
                    file.fileName.includes("pdf") ||
                    file.fileName.includes("txt");

                  if (isNotAnImage) {
                    const fileType = file.fileName.includes("pdf")
                      ? "application/pdf"
                      : "text/plain";
                    return (
                      <DefectFileAttachment key={file.id}>
                        <img
                          src={getFileTypeIcon(fileType)}
                          style={{
                            height: "100%",
                            maxHeight: "60%",
                            marginBottom: 20,
                          }}
                          alt="file icon"
                        />

                        <DefectFileAttachmentName>
                          {file.fileName}
                        </DefectFileAttachmentName>
                      </DefectFileAttachment>
                    );
                  }

                  return (
                    <DefectImageAttachment
                      key={file.id}
                      src={file.filePath + "?h=200"}
                    />
                  );
                })}
              </div>
              <div
                className="margin-top-1 margin-bottom-2 link-style-elem"
                onClick={downloadDefectFiles}
              >
                Download all
              </div>
            </>
          ) : (
            <div className="margin-bottom-2"> NA </div>
          )}

          {/* Comments */}
          <label>Comments</label>
          <CommentsContainer>
            {defect.comments.map((comment, index) => (
              <Comment key={index} comment={comment} />
            ))}
          </CommentsContainer>

          {/* Comment Box */}
          <CommentBox
            logbookId={defect.property.id}
            defectId={defect.id}
            isViewingDefectInsideALogbook={isViewingDefectInsideALogbook}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
