import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import crossIcon from "../assets/images/close.svg";
import tickWhiteIcon from "../assets/images/tick_white.svg";

export const InlineEditorContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  position: relative;

  &.reading {
    overflow: hidden;
  }
`;

export const InlineEditorValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
`;

export const InlineEditorValue = styled.div`
  margin: 0;
  font-size: 16px;
  font-style: normal;
  color: #2d3540;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 100%;
  padding-left: 8px;
  margin-right: 8px;
  transition: all 150ms linear;
  z-index: 1;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.bold {
    font-weight: bold;
  }

  &.editable {
    &:hover {
      cursor: text;
      background-color: #e2e8f685;
      border-radius: 4px;
    }
  }
`;

export const InlineEditorInput = styled.input`
  margin: 0;
  font-size: 16px;
  font-style: normal;
  line-height: 32px;
  color: #2d3540;
  padding-left: 8px;
  max-width: 100%;
  padding-left: 8px;
  margin-right: 8px;
  margin-right: 8px;
  transition: all 150ms linear;
  z-index: 1;
  flex-grow: 1;
  background-color: transparent;
  border: none;

  &.bold {
    font-weight: bold;
  }
`;

export const InlineEditorBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  &.editing {
    background-color: #e2e8f685;
    border-radius: 4px;
  }
`;

export const InlineEditorActions = styled.div`
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 112%;
  right: 2px;
  z-index: 1;
`;

export const InlineEditorAction = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 4px 1px #d8d8d8;

  &.success {
    background-color: #bdd23f;
  }
  &.cancel {
    background-color: #e2e8f6ee;
  }
`;

/**
 *
 * @param {string} fullName
 * @param {boolean} [shouldHandleFileExtension]
 */
const getFileNameAndExtension = (
  fullName,
  shouldHandleFileExtension = false,
) => {
  if (!shouldHandleFileExtension) {
    return [fullName, ""];
  }

  const fileNameParts = fullName.split(".");
  const fileExtension = "." + fileNameParts.pop();

  return [fileNameParts.join(""), fileExtension];
};

export default function InlineEditor(props) {
  const [fileName, fileExtension] = getFileNameAndExtension(
    props.defaultValue,
    props.isDocument,
  );

  const [isEditingValue, setIsEditingValue] = useState(false);
  const [value, setValue] = useState(fileName);
  const [editorRef, setEditorRef] = useState(null);

  // methods
  const checkForKeyboardActions = (e) => {
    const keyCode = e.which || e.keyCode;

    if (keyCode === 13) {
      // enter/return key
      e.preventDefault();
      e.stopPropagation();

      confirmChanges();
    }

    if (keyCode === 27) {
      // escape key
      cancelChanges();
    }
  };

  const cancelChanges = (e) => {
    if (e) {
      stopEventsFromBubblingUp(e);
    }

    finishEditingValue();
  };

  const confirmChanges = (e) => {
    if (e) {
      stopEventsFromBubblingUp(e);
    }

    props.onConfirm(value + fileExtension);
    finishEditingValue();
  };

  const stopEventsFromBubblingUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const startEditingValue = (e) => {
    if (props.editable) {
      stopEventsFromBubblingUp(e);

      if (!isEditingValue) {
        setIsEditingValue(true);
      }
    }
  };

  const finishEditingValue = () => {
    setIsEditingValue(false);
  };

  /**
   * @param {MouseEvent} e
   */
  const checkForNodeInBubble = useCallback(
    (e) => {
      if (editorRef && isEditingValue) {
        if (!editorRef.contains(e.target)) {
          setIsEditingValue((_) => false);
        }
      }
    },
    [editorRef, isEditingValue],
  );

  // side effects
  useEffect(() => {
    document.addEventListener("click", checkForNodeInBubble);

    return () => document.removeEventListener("click", checkForNodeInBubble);
  }, [checkForNodeInBubble]);

  useEffect(() => {
    const [newFileName] = getFileNameAndExtension(
      props.defaultValue,
      props.isDocument,
    );
    setValue((_) => newFileName);
  }, [props.defaultValue, props.isDocument]);

  // render
  return (
    <InlineEditorContainer
      ref={setEditorRef}
      className={isEditingValue ? "" : "reading"}
    >
      <InlineEditorBackground className={isEditingValue ? " editing" : ""} />

      {isEditingValue ? (
        <InlineEditorInput
          autoFocus
          onClick={stopEventsFromBubblingUp}
          className={`truncate` + (props.bold ? " bold" : "")}
          value={value}
          onChange={(e) => {
            const val = e.target.value;
            setValue(val);
          }}
          onKeyDown={checkForKeyboardActions}
        />
      ) : (
        <InlineEditorValueContainer>
          <InlineEditorValue
            className={
              (props.bold ? " bold" : "") + (props.editable ? " editable" : "")
            }
            onClick={startEditingValue}
            style={{
              color: props.color,
            }}
          >
            {value}
          </InlineEditorValue>

          {props.children}
        </InlineEditorValueContainer>
      )}

      {isEditingValue ? (
        <InlineEditorActions>
          <InlineEditorAction className="success" onClick={confirmChanges}>
            <img src={tickWhiteIcon} alt="confirm icon" />
          </InlineEditorAction>
          <InlineEditorAction className="cancel" onClick={cancelChanges}>
            <img src={crossIcon} alt="cancel icon" />
          </InlineEditorAction>
        </InlineEditorActions>
      ) : null}
    </InlineEditorContainer>
  );
}
