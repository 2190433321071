import { createGlobalStyle, css } from "styled-components";
import ProjectBoldFont from "./assets/fonts/lato-black-webfont.woff";
import ProjectSemiboldFont from "./assets/fonts/lato-bold-webfont.woff";
import ProjectFont from "./assets/fonts/lato-regular-webfont.woff";

export const Variables = {
  primaryColor: "#bdd23f",
  darkPrimaryColor: "#a0b42a",
  secondaryColor: "#68c9d6",
  secondaryBackgroundColor: "#d8d8d8",

  /** Text **/
  primaryTextColor: "#2d3540",
  secondaryTextColor: "#9b9b9b",
  headingTextColor: "#203d5e",
  primaryButtonTextColor: "#fff",

  /** Alerts **/
  errorColor: "#FF3E3E",
  successColor: "#4cd964",
  warningColor: "#ffcc00",
};

const createStyle = (prefix) => {
  let style = "";

  for (let i = 1; i <= 12; i++) {
    style += `
      .${prefix}-${i} {
        ${prefix}: ${i * 8}px;
      }
    `;
  }

  return css`
    ${style}
  `;
};

const SpacingStyles = css`
  /** Margins **/
  ${createStyle("margin")}
  ${createStyle("margin-top")}
  ${createStyle("margin-bottom")}
  ${createStyle("margin-left")}
  ${createStyle("margin-right")}
  
  /** Padding **/
  ${createStyle("padding")}
  ${createStyle("padding-top")}
  ${createStyle("padding-bottom")}
  ${createStyle("padding-left")}
  ${createStyle("padding-right")}

  /** Resetters **/
  .depad-left {
    margin-left: -8px;
  }

  .depad-right {
    margin-right: -8px;
  }
`;

const ButtonStyles = css`
  .button {
    padding: 8px 16px;
    cursor: pointer;
    text-decoration: none;
    border: 2px solid;
    transition: all 160ms ease-in-out;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    // Default styles
    border-color: #b6b6ba;
    color: #b6b6ba;

    &:hover {
      background-color: #b6b6ba;
      color: #fff;
    }

    &.button-primary {
      background-color: ${Variables.primaryColor};
      color: ${Variables.primaryButtonTextColor};
      border-color: ${Variables.primaryColor};

      &:hover {
        @if variable-exists(${Variables.darkPrimaryColor}) {
          background-color: ${Variables.darkPrimaryColor};
          border-color: ${Variables.darkPrimaryColor};
        } @else {
          background-color: darken(
            $color: ${Variables.primaryColor},
            $amount: 8
          );
          border-color: darken($color: ${Variables.primaryColor}, $amount: 8);
        }
      }
    }

    &.button-secondary {
      background-color: #fff;
      color: ${Variables.primaryColor};
      border-color: ${Variables.primaryColor};

      &:hover {
        background-color: ${Variables.primaryColor};
        color: #fff;
      }
    }

    &.button-link {
      background-color: #fff;
      color: ${Variables.primaryColor};
      border-color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }

    &.button-archive {
      background-color: #fff;
      color: ${Variables.errorColor};
      border-color: ${Variables.errorColor};

      &:hover {
        background-color: ${Variables.errorColor};
        color: #fff;
      }
    }
    &.button-danger {
      background-color: ${Variables.errorColor};
      color: #fff;
      border-color: ${Variables.errorColor};

      &:hover {
        background-color: darken($color: ${Variables.errorColor}, $amount: 8);
        border-color: darken($color: ${Variables.errorColor}, $amount: 8);
        color: #fff;
      }
    }
    &.button-danger-inverse {
      background-color: transparent;
      color: ${Variables.errorColor};
      border-color: transparent;
    }

    &.button-remove {
      background-color: #fff;
      color: ${Variables.errorColor};
      border-color: transparent;

      &:hover {
        background-color: #fff;
        border-color: transparent;
      }
    }

    &.button-tertiary-disabled {
      background-color: #fff;
      color: #adadb0;
      border-color: transparent;
      cursor: default;
    }

    &.button-tertiary {
      background-color: transparent;
      color: ${Variables.primaryColor};
      border-color: transparent;
    }

    &.button-disabled {
      cursor: not-allowed;
      background-color: ${Variables.secondaryBackgroundColor};
      border-color: ${Variables.secondaryColor};
      color: ${Variables.secondaryTextColor};
    }

    &.full-width {
      width: 100%;
    }

    &.button-large {
      padding: 16px;
      border: none;
    }

    &.button-small {
      padding: 4px 8px;
      font-size: 12px;
    }

    &.button-big {
      font-size: 18px;
    }

    &.button-secondary-link {
      background-color: transparent;
      color: ${Variables.secondaryColor};
      border: none;

      &:hover {
        background-color: transparent;
        color: ${Variables.secondaryColor};
        border: none;
      }
    }

    &.button-accept-ownership {
      background-color: ${Variables.headingTextColor};
      color: ${Variables.primaryButtonTextColor};
      border-color: ${Variables.headingTextColor};
    }

    &.button-warning {
      background-color: ${Variables.warningColor};
      color: ${Variables.primaryButtonTextColor};
      border-color: ${Variables.warningColor};
      min-width: 160px;

      &:hover {
        background-color: darken($color: ${Variables.warningColor}, $amount: 8);
        border-color: darken($color: ${Variables.warningColor}, $amount: 8);
      }
    }

    &.button-warning-urgent {
      background-color: ${Variables.errorColor};
      color: ${Variables.primaryButtonTextColor};
      border-color: ${Variables.errorColor};
      min-width: 160px;

      &:hover {
        background-color: darken($color: ${Variables.errorColor}, $amount: 8);
        border-color: darken($color: ${Variables.errorColor}, $amount: 8);
      }
    }

    &.button-alt {
      background-color: #fff;

      &:hover {
        background-color: #b6b6ba;
      }
    }

    &.no-padding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .link-button {
    color: ${Variables.primaryColor};
    margin: 0;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
  }
`;

const FormStyles = css`
  .form-element {
    width: 100%;

    // Form Label
    .form-label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 6px;
      width: 100%;

      label {
        width: 100%;
      }

      &.lower {
        align-items: flex-end;
      }

      &.upper {
        align-items: flex-start;
      }

      span {
        font-style: italic;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        color: #9b9b9b;
        text-transform: none;
        letter-spacing: normal;
      }
    }

    // Helper Text
    .form-helper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .form-label + .form-helper {
      margin-top: -8px;
      margin-bottom: 8px;
    }

    // Form Input
    .form-input {
      position: relative;
      text-align: left;

      // Like the calendar icon in CalendarPicker
      svg {
        position: absolute;
        right: 8px;
        height: 18px;
        top: calc(50% - 10px);

        .icon {
          fill: ${Variables.primaryColor};
        }
      }

      /** Single Line Input **/
      input {
        width: 100%;
        background-color: transparent;
        // border: 1px solid ${Variables.secondaryTextColor};
        border: 1px solid #dde0e3;
        font-size: 16px;
        color: ${Variables.primaryTextColor};
        padding: 15px 8px;
        line-height: 20px;
        caret-color: ${Variables.primaryColor};
        border-radius: 4px;

        &:focus {
          border-color: ${Variables.secondaryColor};
        }

        &.error {
          border-color: #f26558 !important;
        }
      }

      /** Text Area **/
      textarea {
        width: 100%;
        background-color: transparent;
        // border: 1px solid ${Variables.secondaryTextColor};
        border: 1px solid #bfbfbf;
        font-size: 16px;
        color: ${Variables.primaryTextColor};
        padding: 8px;
        caret-color: ${Variables.primaryColor};
        border-radius: 4px;

        &:focus {
          border-color: ${Variables.secondaryColor};
        }
      }

      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #d8d8d8;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: #d8d8d8;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: #d8d8d8;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: #d8d8d8;
      }

      /** Radios **/
      input[type="radio"] {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 1;

        & + label {
          padding-left: 24px;
          height: auto;
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
          font-weight: 400;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 7px;
            left: 0;
            width: 14px;
            height: 14px;
            border: 1px solid ${Variables.secondaryTextColor};
            border-radius: 50%;
          }
        }

        &:checked + label {
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            left: 3px;
            width: 10px;
            height: 10px;
            background-color: #bfbfbf;
            border-radius: 50%;
          }
        }
      }

      /** Checkboxes **/
      // input[type='checkbox'] {
      // 	position: absolute;
      // 	width: 100%;
      // 	height: 100%;
      // 	opacity: 0;
      // 	cursor: pointer;
      // 	z-index: 1;
      // 	top: 0;
      // 	left: 0;

      // 	& + label {
      // 		padding-left: 32px;
      // 		height: auto;
      // 		display: inline-block;
      // 		background-position: 0 2px;
      // 		vertical-align: middle;
      // 		cursor: pointer;
      // 		font-weight: 400;
      // 		font-size: 16px;
      // 		line-height: 22px;
      // 		color: #bfbfbf;
      // 		text-transform: none;
      // 		letter-spacing: normal;

      // 		&::before {
      // 			content: '';
      // 			display: block;
      // 			position: absolute;
      // 			top: 2px;
      // 			left: 0;
      // 			width: 20px;
      // 			height: 20px;
      // 			border-radius: 4px;
      // 			// border: 1px solid ${Variables.secondaryTextColor};
      // 			border: 1px solid #bfbfbf;
      // 		}
      // 	}

      // 	&:checked + label {
      // 		&:after {
      // 			content: '';
      // 			display: block;
      // 			position: absolute;
      // 			top: 6px;
      // 			left: 3.5px;
      // 			width: 12px;
      // 			height: 6px;
      // 			border-bottom: 3px solid ${Variables.primaryColor};
      // 			border-left: 3px solid ${Variables.primaryColor};
      // 			transform: rotate(-45deg);
      // 		}
      // 	}
      // }

      /** Dropzone **/
      .dropzone {
        .dropzone-placeholder {
          margin-top: 16px;
          height: 144px;
          border: 1px dashed #cacaca;
          border-radius: 16px;
          position: relative;
          cursor: pointer;

          &:hover {
            border-color: ${Variables.primaryColor};
            background-color: rgba(189, 210, 63, 0.08);
          }

          svg {
            position: absolute;
            top: -16px;
            left: calc(50% - 16px);
            width: 32px;
            height: 32px;

            circle {
              fill: ${Variables.primaryColor};
            }
          }
        }
      }

      /** File Preview **/
      .file-preview {
        margin-top: 16px;
        position: relative;
        padding: 8px;

        svg {
          position: relative;
          margin-left: 16px;
        }

        .single-file-preview {
          max-width: 100%;
          max-height: 45vh;
        }

        &.multiple {
          .preview {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            p {
              font-size: 14px;
              line-height: 16px;
              margin-left: 8px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

      &.toggle {
        width: 120px;
        height: 48px;

        input[type="checkbox"] {
          height: 46px;

          & + label {
            border: none;

            &::before {
              width: 100%;
              height: 40px;
              border-radius: 20px;
              background-color: white;
            }

            &::after {
              content: "";
              display: block;
              position: absolute;
              width: 38px;
              height: 38px;
              border-radius: 50%;
              top: 4px;
              left: 2px;
              right: unset;
              background-color: #fff;
              box-shadow: 0 0 4px 1px #00000030;
              transition: all 15000ms linear;
            }

            span {
              &::before,
              &::after {
                content: "";
                position: absolute;
                right: 8px;
                top: 22px;
                width: 20px;
                height: 1px;
                background-color: #f26558;
              }

              &::before {
                transform: rotate(-45deg);
              }

              &::after {
                transform: rotate(45deg);
              }
            }
          }

          &:checked + label {
            border: none;

            &::before {
              background-color: #bdd23f;
            }

            &::after {
              content: "";
              display: block;
              position: absolute;
              width: 38px;
              height: 38px;
              border-radius: 50%;
              left: unset;
              right: 0;
              border: none;
            }
          }
        }
      }
    }

    // Form Error
    .form-error {
      p {
        color: ${Variables.errorColor} !important;
        text-align: left;
      }
    }

    // Customising Form Elements
    &.new-radio {
      width: auto;
      display: inline-flex;

      .form-input {
        input[type="radio"] {
          left: 0;
          top: 0;

          & + label {
            margin: 0;
            padding: 8px 16px;
            color: #bfbfbf;
            text-transform: none;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: normal;

            &:before {
              content: "";
              display: none;
            }
          }

          &:checked + label {
            background-color: #fff;
            color: ${Variables.primaryColor};

            &:after {
              content: "";
              display: none;
            }
          }
        }
      }
    }
  }

  .radios {
    border: 1px solid #e2e8f6;
    background-color: #fafcff;
    display: inline-block;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    .new-radio {
      .form-input {
        input[type="radio"] {
          &:checked + label {
            border-left: 1px solid #e2e8f6;
            border-right: 1px solid #e2e8f6;
          }
        }
      }
    }

    .new-radio:first-of-type {
      .form-input {
        input[type="radio"] {
          &:checked + label {
            border-left: none;
          }
        }
      }
    }

    .new-radio:last-of-type {
      .form-input {
        input[type="radio"] {
          &:checked + label {
            border-left: 1px solid #e2e8f6;
            border-right: none;
          }
        }
      }
    }
  }
`;

const LayoutStyles = css`
  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.top {
      align-items: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.end {
      justify-content: flex-end;
    }

    &.bottom {
      align-items: flex-end;
    }

    &.between {
      justify-content: space-between;
    }

    &.col {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &.top {
        justify-content: flex-start;
      }

      &.center {
        align-items: center;
      }

      &.end {
        align-items: flex-end;
      }

      &.bottom {
        justify-content: flex-end;
      }
    }

    &.wrap {
      flex-wrap: wrap;
    }

    @for $i from 0 through 10 {
      .width-#{$i * 10} {
        width: $i * 10%;
      }
    }
  }

  .auto-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

const TypographyStyles = css`
  h1 {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${Variables.headingTextColor};
  }

  .button {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  body,
  p {
    font-size: 16px;
    line-height: 24px;
  }

  label {
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #9b9b9b;
  }

  .helper-text {
    font-size: 14px;
    line-height: 16px;
    color: ${Variables.secondaryTextColor};

    &.top {
      line-height: 20px;
    }

    &.bottom {
      line-height: 16px;
    }
  }
  .helper-text-alt {
    font-size: 14px;
    line-height: 22px;
  }

  a {
    text-decoration: none;
  }

  .link {
    text-decoration: none;
    font-size: 14px;
    line-height: 16px;
    // color: ${Variables.primaryColor};
    color: #68c9d6;
  }

  .all-caps {
    text-transform: uppercase;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  @media only screen and (max-width: 575px) {
    h1 {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;

const ModalStyles = css`
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(32, 61, 94, 0.7);
    z-index: 999;

    .modal-content {
      position: relative;
      width: 100%;
      max-width: 504px;
      height: auto;
      background: #ffffff;
      border-radius: 16px;
      padding: 16px 32px 32px;

      .title {
        color: #3b3b3b;
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .explanation {
        font-size: 13px;
      }

      .close {
        position: absolute;
        top: 24px;
        right: 24px;
        border: none;
        cursor: pointer;
      }

      .input-group {
        width: 100%;
      }

      @media only screen and (max-width: 576px) {
        height: 100vh;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;

const ReactWidgetStyles = css`
  .rw-widget-container {
    border-radius: 4px !important;
  }

  .rw-widget-container.rw-state-focus {
    box-shadow: none;
    border-color: ${Variables.secondaryTextColor};
  }

  .rw-widget {
    &.rw-state-focus > .rw-widget-container {
      box-shadow: none;
      border-color: ${Variables.secondaryTextColor};
    }
    &.rw-state-focus > .rw-widget-container:hover {
      box-shadow: none;
      border-color: ${Variables.secondaryTextColor};
    }

    .rw-popup-container {
      // Uncomment the line below to hide the empty white space above the pop up
      // top: 0;

      .rw-popup {
        border: none;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }

    // Calendar
    &.rw-calendar {
      position: absolute;
      top: 56px;
      z-index: 1;

      .rw-cell.rw-state-focus,
      .rw-cell.rw-state-focus:hover {
        border-color: ${Variables.primaryColor};
      }

      .rw-cell.rw-state-selected,
      .rw-cell.rw-state-selected:hover {
        border-color: ${Variables.primaryColor};
        background-color: ${Variables.primaryColor};
      }

      svg {
        position: unset;
        right: unset;
        height: 1em;
        top: unset;
      }
    }

    // MultiSelect
    &.rw-multiselect {
      .rw-widget-picker {
        div {
          ul {
            .rw-multiselect-tag {
              background-color: ${Variables.primaryColor};
              border-color: ${Variables.primaryColor};
              color: #fff;
              font-weight: normal;

              span {
                color: #fff;
              }
            }
          }

          input {
            margin-top: 2px;
            margin-bottom: 2px;
            border: none;
            height: 36px;

            &:focus {
              border: none;
            }
          }
        }
      }
    }

    // Select, MultiSelect and Dropdown
    &.rw-dropdown-list,
    &.rw-multiselect {
      .rw-widget-picker {
        height: 48px;
        border-radius: 0;

        .rw-filter-input {
          padding-left: 0;
        }
      }

      .rw-popup-container {
        .rw-popup-transition {
          .rw-popup {
            ul {
              .rw-list-option {
                &.rw-state-focus,
                &.rw-state-focus:hover {
                  border-color: ${Variables.primaryColor};

                  &.rw-state-selected {
                    background-color: ${Variables.primaryColor};
                    border-color: ${Variables.primaryColor};
                  }
                }

                &.rw-state-selected {
                  background-color: ${Variables.primaryColor};
                  border-color: ${Variables.primaryColor};
                }
              }
            }
          }
        }
      }
    }
  }
`;

const PopperStyles = css`
  .popper {
    background: #222;
    color: white;
    width: 250px;
    border-radius: 2px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    padding: 5px;
    z-index: 1000;
  }

  .popper .popper__arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
  }

  .popper[data-placement^="top"] {
    margin-bottom: 5px;
  }

  .popper[data-placement^="top"] .popper__arrow {
    border-width: 5px 5px 0 5px;
    border-color: #222 transparent transparent transparent;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .popper[data-placement^="bottom"] {
    margin-top: 5px;
  }

  .popper[data-placement^="bottom"] .popper__arrow {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #222 transparent;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .popper[data-placement^="right"] {
    margin-left: 5px;
  }

  .popper[data-placement^="right"] .popper__arrow {
    border-width: 5px 5px 5px 0;
    border-color: transparent #222 transparent transparent;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .popper[data-placement^="left"] {
    margin-right: 5px;
  }

  .popper[data-placement^="left"] .popper__arrow {
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #222;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .popper[data-x-out-of-boundaries] {
    display: none;
  }
`;

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ProjectFont';
    src: url(${ProjectFont}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'ProjectFont';
    src: url(${ProjectSemiboldFont}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'ProjectFont';
    src: url(${ProjectBoldFont}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  /** Style Reset **/
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    &:active,
    &:focus {
      outline: none;
    }
  }

  /** Importing Styles **/
  ${SpacingStyles}
  ${ButtonStyles}
  ${FormStyles}
  ${LayoutStyles}
  ${TypographyStyles}
  ${ModalStyles}
  ${ReactWidgetStyles}
  ${PopperStyles}

  * {
    font-family: ProjectFont;
    font-smooth: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::selection {
      background: rgba(189,210,63, 0.3);
    }
  }

  body {
    background-color: #f6f7fb;
    color: ${Variables.primaryTextColor};
  }

  input::placeholder {
    color: #9b9b9b;
  }

  .react-select__placeholder {
    color: #9b9b9b !important;
  }

  .inverse {
    * {
      color: #fff;
    }
  }

  code {
    font-family: monospace;
  }

  /** Other Styles **/

  /** Alerts **/
  .s-alert-box {
    padding: 24px;

    .s-alert-box-inner {
      color: #fff;
    }

    .s-alert-close {
      right: 16px;
      top: calc(50% - 10px);
    }
  }

  .s-alert-error {
    background-color: ${Variables.errorColor};
  }

  /** Breadcrumbs **/
  .breadcrumbs {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;

    .breadcrumbs__inner {
      .breadcrumbs__section {
        margin-right: 8px;

        .breadcrumbs__crumb {
          font-size: 12px;
          text-transform: uppercase;
          color: ${Variables.secondaryColor};
          font-weight: 600;
          margin-right: 8px;
          opacity: 0.72;
        }

        &:last-of-type {
          .breadcrumbs__crumb {
            font-weight: 700;
            letter-spacing: 1.1px;
            opacity: 1;
          }
        }
      }
    }
  }

  .container {
    min-height: calc(100vh - 136px);
    padding-bottom: 32px;
  }

  // React Widgets Dropdown
  .rw-list-option:hover,
  .rw-list-option.rw-state-selected:hover {
    background-color: rgba(189, 210, 63, 0.27);
    color: #333;
    border: none;
  }

  .rw-list-option.rw-state-selected {
    background-color: #a0b42a;
    color: #fff;
    border: none;
  }

  .rw-list-option {
    white-space: nowrap;
    line-height: 36px;
    color: #333;
    border: none;
  }

  /** inndox components */
  .property,
  .company {
    .cover {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;

      .address-info {
        background: rgba(21,30,44, 0.91);
        border-radius: 4px;
        max-width: 90%;

        img {
          max-width: 300px;
          @media only screen and (max-width: 575px) {
            max-width: 200px;
          }
        }
      }

      h2 {
        color: #fff;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        max-width: 100%;
      }

      p {
        color: #68c9d6;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1.1px;
      }
    }

    .form-element.search {
      input {
        border-radius: 100px;
        background-color: #fff;
        padding: 16px 20px;
        padding-left: 40px;
      }
    }

    .cancel-search {
      position: absolute;
      right: 8px;
      width: 36px;
      height: 36px;
      top: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .folder-item {
      background: #ffffff;
      border: 1px solid #dde0e3;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      //overflow: hidden;
      min-width: 0;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        //white-space: nowrap;
      }

      .close-button {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: calc(50% - 12px);
        cursor: pointer;
      }
    }

    .dragging {
      cursor: none !important; /* fallback: no 'url()' support or images disabled */
      background-color: #fff;
      padding: 0 16px;
      border-radius: 4px;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      opacity: 0.5;
    }

    .dragging span,
    .dragging a,
    .dragging img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }

    .dragging-over .drop-on-folder {
      opacity: 1;
      z-index: 999;
      background-color: #fff;
      box-shadow: 0px 0px 4px #bdd23f;
      opacity: 0.75;
    }

    .drop-on-folder {
      color: #a0b42a;
      display: flex;
      opacity: 0;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: none;
      transition: opacity 0.2s ease-out, height 0.2s ease-out, width 0.2s ease-out;
      z-index: -5;
      border-radius: 4px;
    }

    .drop-on-folder {
      .drop-icon {
        opacity: 0.75;

        path {
          color: #bdd23f;
        }

        @keyframes fade {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0.25;
          }
        }
      }

      .drop-icon.fade {
        -webkit-animation: fade 0.75s infinite;
        animation: fade 0.75s infinite;
        -moz-animation: fade 0.75s infinite;
        -o-animation: fade 0.75s infinite;
      }
    }

    .sharing-info {
      margin-right: 16px;
      flex-direction: row-reverse;

      .person {
        margin-right: -8px;
      }
    }
  }

  .person {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 8px;
    background-color: #adadb0;
    cursor: pointer;

    p {
      color: #fff;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      font-size: 14px;
      line-height: auto;
    }
  }

  .person-item {
    height: 72px;
    border-bottom: 1px solid rgba(151,151,151, 0.5);
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255, 0.5);
    }
  }

  .person-list-item {
    &:last-of-type {
      .person-item {
        border-bottom: none;
      }
    }
  }

  .document-empty-state {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 32px;

    img {
      margin-right: 48px;
    }

    h1 {
      color: #626163;
    }

    p {
      color: #adadb0;
      font-size: 20px;
      line-height: 32px;
    }

    @media only screen and (max-width: 575px) {
      flex-direction: column;
      align-items: center;

      .flex.col {
        align-items: center;
      }

      h1 {
        margin-top: 32px;
      }

      h1,
      p {
        text-align: center;
      }
    }
  }

  .document-list {
    background-color: #fff;
    border-radius: 10px;
    padding: 32px;
    padding-bottom: 8px;

    &.drag-highlight {
      background-color: #efefef;
    }

    @media only screen and (max-width: 576px) {
      padding-top: 16px;
    }

    .empty-state {
      margin-bottom: 16px;
      font-size: 16px;
      color: #adadb0;
    }

    &.bottom-only {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .warranty-label,
    .maintenance-item-due-date-label,
    .status-label {
      height: 20px;
      font-weight: 600;
      font-size: 12px;
      color: #ffffff;
      letter-spacing: 0;
      display: flex;
      align-items: center;
      padding: 2px 4px;
      border-radius: 4px;

      img {
        margin-right: 4px;
      }

      &.info {
        background-color: #3f8dd2;
      }

      &.warning {
        background-color: #ff9600;
      }

      &.valid {
        background-color: #bfd62f;
      }

      &.expired,
      &.error {
        background-color: #ed3524;
      }

      &.archived {
        background-color: #999;
      }
    }

    .col-head,
    .col-body {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .ellipsize {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .col-head {
      height: 56px;
      text-transform: uppercase;
      font-weight: 700;
      color: #bfbfbf;
      font-size: 12px;
      letter-spacing: 1.2px;
    }

    .col-body {
      min-height: 72px;
      border-top: 1px solid #dde0e3;
      align-items: center !important;
      padding-top: 16px;
      padding-bottom: 16px;

      .file-type-icon {
        flex-shrink: 0;
        margin-right: 16px;
      }
    }

    .editing {
      form {
        width: 100%;
      }
    }

    .filename {
      flex-wrap: wrap;
      width: 100%;

      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        max-width: calc(100% - 43px);
      }
    }

    .email-display {
      max-width: 60%;
    }

    .email-display:hover {
      text-decoration: underline;
    }

    &.grey {
      background-color: #b6b6ba;
    }
  }

  .truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-all;
  }

  .folder {
    h2.name {
      font-size: 24px;
      color: #626163;
      font-weight: 600;

      svg {
        margin-left: 8px;

        &.clickable {
          cursor: pointer;
        }

        path {
          fill: #adadb0;
        }

        &.clickable:hover {
          path {
            fill: #bdd23f;
          }
        }
      }
    }

    .description {
      margin-top: 8px;
      line-height: normal;
      color: #adadb0;
    }
  }

  .separator {
    width: 1px;
    height: 200px;
    background-color: rgba(151,151,151, 0.19);
    position: absolute;
    left: -16px;
    top: 0;
  }

  .transfer-features {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 16px;

      .check {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        background-color: #bfd62f;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        margin-top: 2px;
      }
    }
  }

  .setup-block {
    border-radius: 10px;
    background-color: #fff;
    padding: 24px;
    text-align: center;

    .text {
      text-align: center;
      align-self: center;
    }
  }

  .link-style-elem {
    cursor: pointer;
    color: #68c9d6;
    transition: color 0.2s ease-out;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: #b1f6ff;
    }
  }

  .articles {
    padding-top: 0;

    .article {
      margin-top: 16px;
      border-radius: 4px;
      padding-left: 8px;
      padding-right: 8px;

      .image {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #333;
        background-size: cover;
        background-position: 50% 15%;
        background-repeat: no-repeat;
        padding: 0;

        &:before {
          padding-top: 56.25%;
          content: "";
          display: block;
        }

        @media screen and (max-width: 575px) {
          min-height: 175px;
        }
      }

      .text {
        padding: 8px;
      }

      .title {
        font-size: 18px;
        line-height: 32px;
        max-width: 100%;
      }
    }
  }

  .prefix-tag {
    border-radius: 4px;
    padding: 1px 6px;
    font-size: 12px;
    width: 100%;
    text-align: center;
    max-width: 75px;
    cursor: pointer;
    transition: opacity 0.2s ease-out;
    text-transform: capitalize;
    font-weight: bold;

    &:hover {
      opacity: 0.6;
    }

    &:hover.prefix-tag-no-hover {
      opacity: 1;
      cursor: default;
    }
  }

  .col-body.thick {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .col-body.locked p {
    color: #adadb0;
  }

  .received-email {
    .subject.failed:after {
      content: "Failed";
      color: white;
      background-color: rgba(255, 0, 0, 0.6);
      font-size: 12px;
      padding: 0 6px;
      border-radius: 4px;
      margin-left: 5px;
    }
  }

  .button.cover-button {
    background-color: transparent;
    border: none;
    color: #68c9d6;

    &:hover {
      background-color: transparent;
      color: #68c9d6;
      border: none;
    }
  }

  .page:before {
    content: "";
    position: absolute;
    top: 1px;
    right: 9px;
    border-top: 30px solid #f6f7fb;
    border-left: 30px solid #dfdee3;
    margin-right: -1px;
    margin-top: -1px;
    width: 0;
  }

  .uppercase {
    text-transform: uppercase;
  }

  /** Tours **/
  .intro-tour-helper {
    nav {
      flex-grow: 1;
    }
  }

  /** Aspect Ratio Enabled Container **/
  .aspect-ratio-16-9 {
    position: relative;
    width: 100%;

    &:after {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
  }

  /** Toastify */
  :root {
    --toastify-color-success: #4cd964;
    --toastify-color-info: #0075e0;
    --toastify-color-warning: #ffcc00;
    --toastify-color-error: #f26558;

    --rt-color-dark: #203d5e;
    --rt-opacity: 1;
  }

  .Toastify__toast-container.Toastify__toast-container--top-center {
    width: 80%;
    max-width: 800px;
  }

  @media only screen and (max-width: 576px) {
    .Toastify__toast-container.Toastify__toast-container--top-center {
      width: 95%;
      transform: translateX(3%);
      top: 24px;
    }
  }

  .Toastify__toast-container.Toastify__toast-container--bottom-left {
    width: 32%;
    max-width: 450px;
  }

  .Toastify__toast-icon.Toastify--animate-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Toastify__toast.Toastify__toast-theme--light {
    border-radius: 10px;
    position: relative;
  }

  .Toastify__toast-body {
    padding: 0;
    max-width: calc(100% - 40px);

    &.notification {
      align-items: flex-start;
    }
  }
`;
