export const AccountRoutes = {
  basePath: "account/*",
  login: "login",
  logout: "logout",
  register: "register",
  forgotPassword: "forgotPassword",
  resetPassword: "resetPassword",
  acceptInvitation: "acceptInvite",
  impersonate: "impersonate",
  token: "login-token/:loginToken",
  subscribe: "subscribe",
  subscriptionInactive: "inactive-subscription",
  addPaymentDetails: "add-payment-details",
  confirmEmail: "confirmemail",

  // Settings Routes
  settingsBasePath: "settings/*",
  personalInfo: "personal-info",
  companyInfo: "company-info",
  accessSettings: "access",
  integrations: "integrations",
  statistics: "statistics",
  billing: "billing",
  logbooks: "logbooks", // only for owner
};

export const CompanyRoutes = {
  basePath: "company/*",
  home: "home",
  // logbook
  logbooks: "logbooks",
  newLogbookType: "logbooks/new/type",
  newLogbookDetails: "logbooks/new/details",
  newLogbookFirstFile: "logbooks/new/add-file",
  logbook: "logbooks/:logbookId",
  editLogbook: "edit",
  logbookTransfer: "handover",
  // folder
  logbookFolders: "folders",
  addFolder: "new-folder",
  logbookFolder: "folder/:folderId",
  logbookSubFolder: "folder/:folderId/sub-folder/:subFolderId",
  editFolder: "folder/:folderId/edit-folder",

  // contacts
  contacts: "contacts",
  viewCompanyContact: "contacts/:contactId",
  addCompanyContact: "contacts/new",
  editCompanyContact: "contacts/:contactId/edit",
  logbookContacts: "contacts",
  viewLogbookContact: "contacts/:contactId",
  addLogbookContact: "contacts/new",
  editLogbookContact: "contacts/:contactId/edit",
  // defects
  defects: "defects",
  companyDefect: "defects/:defectId",
  assignCompanyDefect: "defects/:defectId/assign",
  logbookDefects: "defects",
  addRecipientForLogbookDefects: "defects/add-recipient",
  logbookDefect: "defects/:defectId",
  addLogbookDefect: "defects/new",
  editLogbookDefect: "defects/:defectId/edit",
  assignLogbookDefect: "defects/:defectId/assign",
  // maintenance items
  logbookMaintenanceItems: "maintenance",
  addLogbookMaintenanceItem: "maintenance/new",
  viewLogbookMaintenanceItem: "maintenance/:maintenanceId",
  editLogbookMaintenanceItem: "maintenance/:maintenanceId/edit",
  // edit template
  editTemplate: "template/:templateId/edit",
  // apply template
  applyTemplate: "apply-template",
  // sub companies
  subCompanies: "associatedCompanies",
  // shared
  sharedLogbooks: "shared",
  sharedLogbookFolder: "shared/:logbookId/folder/:folderId",
  // warranty
  warranties: "warranties",
  // newWarranty: "warranties/new",
  // editWarranty: "warranties/:documentId/edit",
  // add users -> now a modal
};

export const OwnerRoutes = {
  // This is also a list of properties
  // as well as shared folders via tab interface
  basePath: "owner/*",
  home: "home",
  landing: "landing",
  // logbook
  logbook: "logbooks/:logbookId",
  editLogbook: "edit",
  newLogbookType: "logbooks/new/type",
  newLogbookDetails: "logbooks/new/details",
  newLogbookFirstFile: "logbooks/new/add-file",
  logbookTransfer: "transfer",
  // folder
  logbookFolders: "folders",
  logbookFolder: "folder/:folderId",
  logbookSubFolder: "folder/:folderId/sub-folder/:subFolderId",
  addFolder: "new-folder",
  editFolder: "folder/:folderId/edit-folder",

  // contacts
  logbookContacts: "contacts",
  viewLogbookContact: "contacts/:contactId",
  addLogbookContact: "contacts/new",
  editLogbookContact: "contacts/:contactId/edit",
  // defects
  logbookDefects: "defects",
  logbookDefect: "defects/:defectId",
  addLogbookDefect: "defects/new",
  editLogbookDefect: "defects/:defectId/edit",

  // maintenance items
  logbookMaintenanceItems: "maintenance",
  addLogbookMaintenanceItem: "maintenance/new",
  viewLogbookMaintenanceItem: "maintenance/:maintenanceId",
  editLogbookMaintenanceItem: "maintenance/:maintenanceId/edit",
  // warranty
  warranties: "warranties",
  // newWarranty: "warranties/new",
  // editWarranty: "warranties/:documentId/edit",
  // archive
  logbookArchive: "archive",
};

export const SharedRoutes = {
  basePath: "shared/*",
  home: "home",
  folder: "logbook/:logbookId/folder/:folderId",
};

export const TemplateRoutes = {
  templates: "templates",
  template: "templates/:templateId",
  newTemplateFolder: "folder/new",
  templateFolders: "folders",
  templateFolder: "folder/:folderId",
  templateSubFolder: "folder/:folderId/sub-folder/:subFolderId",
  templateContacts: "contacts",
  addTemplateContact: "contacts/new",
  viewTemplateContact: "contacts/:contactId",
  editTemplateContact: "contacts/:contactId/edit",
  templateMaintenanceItems: "maintenance",
  addTemplateMaintenanceItem: "maintenance/new",
  editTemplateMaintenanceItem: "maintenance/:maintenanceId/edit",
  viewLogbookMaintenanceItem: "maintenance/:maintenanceId",
  newTemplate: "templates/new",
};

export const PublicRoutes = {
  basePath: "public/*",
  snooze: "maintenance-item/snooze",
  defects: "defects",
  publicFolder: "foldershare/:accessCode",
};
